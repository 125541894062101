<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb title="Hizmet Listesi" :array="[{islink:true,url:'/',text:'Ana Sayfa'},{islink:false,url:'',text:'Hizmet Listesi'}]"/>
    </section>
    <!--/Breadcrumb-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <template v-if="isLoad">
            <div class="row text-center">
              <h2>Hizmetler getiriliyor</h2>
            </div>
          </template>
          <template v-if="filterList.length == 0 && !isLoad">
            <div class="row text-center">
              <h2>Hizmet Bulunamadı!</h2>
            </div>
          </template>
          <div
            class="col-lg-3 col-md-12"
            v-for="(item, i) in filterList"
            :key="'cityCard' + i"
          >
            <router-link :to="'/discomfort/' + item.shortname">
              <div class="card" style="min-height: 160px">
                <div class="card-body p-2">
                  <div class="item-box text-center">
                    <div
                      class="
                        bg-purple-transparent
                        icon-bg icon-service
                        text-purple
                      "
                    >
                      <i
                        class="fa fa-heart"
                        style="color: rgb(195 133 162)"
                      ></i>
                    </div>
                    <div class="item-box-wrap">
                      <h5
                        style="font-weight: 500; font-size: 15px"
                        class="mb-2"
                      >
                        {{ item.tag }}
                      </h5>
                      <p class="text-muted mb-0"></p>
                    </div>
                  </div>
                </div>
              </div>
            </router-link>
          </div>

          <div class="center-block text-center">
            <ul class="pagination mb-5" style="overflow-x: scroll">
              <li
                v-for="(item, i) in alphabet"
                :key="'alphapet' + i"
                class="page-item m-1"
              >
                <button class="page-link" @click="filter(item)">
                  {{ item }}
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import Breadcrumb from '../components/Breadcrumb.vue';
export default {
  metaInfo() {
    return {
      title: "Hastalık, terapi ve medikal durumlar: trendlerde ara",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Türkiye’de en çok aranan terapiler, tedaviler hastalıklar ve onlarla alakalı en sık sorulan sorular Terapivitrini.com da bulun.",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;
    this.$store.dispatch("setMetaContent", [
      "Hastalık, terapi ve medikal durumlar: trendlerde ara",
      "Türkiye’de en çok aranan terapiler, tedaviler hastalıklar ve onlarla alakalı en sık sorulan sorular Terapivitrini.com da bulun.",
      "",
      "",
      "",
    ]);
    this.$store.dispatch("serviceTagList").then((value) => {
      this.serviceTagList = value.list;
      this.filter(this.default);
      this.isLoad = false;
    });
  },
  data() {
    return {
      isLoad: true,
      default: "A",
      alphabet: [
        "A",
        "B",
        "C",
        "Ç",
        "D",
        "E",
        "F",
        "G",
        "H",
        "İ",
        "I",
        "K",
        "L",
        "M",
        "N",
        "O",
        "Ö",
        "P",
        "R",
        "S",
        "Ş",
        "T",
        "U",
        "Ü",
        "V",
        "Y",
        "Z",
      ],
      filterList: [],
      serviceTagList: [],
    };
  },
  components: { Breadcrumb },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    filter(item) {
      this.filterList = this.serviceTagList.filter((country) =>
        country.tag.toUpperCase().startsWith(item.toUpperCase())
      );
    },
  },
  mounted() {
    this.backToTop();
    var owl = $(".testimonial-owl-carousel");
    owl.owlCarousel({
      loop: true,
      rewind: false,
      margin: 25,
      autoplay: true,
      animateIn: "fadeInDowm",
      animateOut: "fadeOutDown",
      autoplay: false,
      autoplayTimeout: 5000, // set value to change speed
      autoplayHoverPause: true,
      dots: false,
      nav: true,
      responsiveClass: true,
      responsive: {
        0: {
          items: 1,
          nav: true,
        },
      },
    });
  },
};
</script>